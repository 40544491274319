import React from "react";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
const PatientsCard = ({ count, resultType, cls, icons }) => {
  return (
    <Col lg="3" sm="6">
      <Card className="card-stats">
        <CardBody>
          <div className="statistics statistics-horizontal">
            <div className="info info-horizontal">
              <Row>
                <Col xs="5">
                  <div className={cls}>
                    <i className={icons} />
                  </div>
                </Col>
                <Col className="text-right" xs="7">
                  <h3 className="info-title">{count}</h3>
                  <h6 className="stats-title">{resultType}</h6>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
        <hr />
        <CardFooter>
          <div className="stats">
            <i className="now-ui-icons arrows-1_refresh-69" />
            Update now
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default PatientsCard;
