import { apiSlice } from "../../app/api/apiSlice";
import { setPatientsList } from "./patientsSlice";

export const patientsApiSlice = apiSlice.injectEndpoints({
  reducerPath: "patientsList",
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: (userId) => ({
        url: `/patients/${userId}`,
      }),
      providesTags: ["Patient"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          const { patientsList } = data;
          dispatch(setPatientsList(patientsList));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    uploadDicomZip: builder.mutation({
      query: (uploadData) => ({
        url: "/patients/uploads",
        method: "POST",
        body: uploadData,
      }),
      invalidatesTags: ["Patient"],
    }),
    deletePatient: builder.mutation({
      query: (patientsIds) => ({
        url: `/patients/delete`,
        method: "PUT",
        body: { patientsIds },
      }),
      invalidatesTags: ["Patient"],
    }),
  }),
});

export const {
  useGetPatientQuery,
  useGetPatientsQuery,
  useDeletePatientMutation,
  useUploadDicomZipMutation,
} = patientsApiSlice;
