import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Button,
} from "reactstrap";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footer/Footer";
// core components
import bgImage from "../assets/img/bg16.jpg";

const RegisterPage = () => {
  const [firstnameFocus, setfirstnameFocus] = useState(false);
  const [lastnameFocus, setlastnameFocus] = useState(false);
  const [emailFocus, setemailFocus] = useState(false);
  useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  }, []);
  return (
    <>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color="yellow">
          <div className="content">
            <div className="register-page">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5} md={8} xs={12}>
                    <div className="info-area info-horizontal mt-5">
                      <div className="icon icon-primary">
                        <i className="now-ui-icons media-2_sound-wave" />
                      </div>
                      <div className="description">
                        <h5 className="info-title">Marketing</h5>
                        <p className="description">
                          We've created the marketing campaign of the website.
                          It was a very interesting collaboration.
                        </p>
                      </div>
                    </div>
                    <div className="info-area info-horizontal">
                      <div className="icon icon-primary">
                        <i className="now-ui-icons media-1_button-pause" />
                      </div>
                      <div className="description">
                        <h5 className="info-title">Fully Coded in React 16</h5>
                        <p className="description">
                          We've developed the website with React 16, HTML5 and
                          CSS3. The client has access to the code using GitHub.
                        </p>
                      </div>
                    </div>
                    <div className="info-area info-horizontal">
                      <div className="icon icon-info">
                        <i className="now-ui-icons users_single-02" />
                      </div>
                      <div className="description">
                        <h5 className="info-title">Built Audience</h5>
                        <p className="description">
                          There is also a Fully Customizable CMS Admin Dashboard
                          for this product.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={8} xs={12}>
                    <Card className="card-signup">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">Register</CardTitle>
                        <div className="social btns-mr-5">
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                          <Button
                            className="btn-icon btn-round"
                            color="dribbble"
                          >
                            <i className="fab fa-dribbble" />
                          </Button>
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </Button>
                          <h5 className="card-description">or be classical</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <InputGroup
                            className={
                              firstnameFocus ? "input-group-focus" : ""
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="User Name..."
                              onFocus={(e) => setfirstnameFocus(true)}
                              onBlur={(e) => setfirstnameFocus(false)}
                            />
                          </InputGroup>

                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="email"
                              placeholder="Email..."
                              onFocus={(e) => setemailFocus(true)}
                              onBlur={(e) => setemailFocus(false)}
                            />
                          </InputGroup>
                          <InputGroup
                            className={lastnameFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Password..."
                              onFocus={(e) => setlastnameFocus(true)}
                              onBlur={(e) => setlastnameFocus(false)}
                            />
                          </InputGroup>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" />
                              <span className="form-check-sign" />
                              <div>
                                I agree to the{" "}
                                <a href="#something">terms and conditions</a>.
                              </div>
                            </Label>
                          </FormGroup>
                        </Form>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          color="primary"
                          size="lg"
                          className="btn-round"
                          href="#pablo"
                        >
                          Get Started
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
