// Import React package...in order to use JSX
import React, { useCallback, useState } from "react";
// Package that will be used to parse the DICOM images
import dicomParser from "dicom-parser";
// Package that will be used to support the document uplaod feature
import { useDropzone } from "react-dropzone";

const DicomHeader = ({ onSelectFolder, onUploadFileStatus }) => {
  // State variables that will be used to set and store the data
  // that we are parsing from the images
  const [parseError, setParseError] = useState("");
  const [sopInstanceUid, setSopInstanceUid] = useState("");
  const [patientId, setPatientId] = useState("");
  const [otherPatientIds, setOtherPatientIds] = useState("");
  const [folderNameList, setFolderNameList] = useState([]);
  const [dcomFileList, setDcomFileList] = useState({});

  // Styling to make the text white
  const textStyle = {
    // color: "white",
  };

  // Called when an application is uploaded
  const onDrop = useCallback((acceptedFiles) => {
    onUploadFileStatus({
      isSorted: true,
      isUpload: false,
    });
    // Do something with the files
    clearPage();
    loadFile(acceptedFiles, onDone);
    // onSelectFolder(folderNameList);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const onDone = (fileList) => {
    console.log(fileList);
    onSelectFolder(Object.keys(fileList), fileList);
  };
  // Clears the data in the variables used to store the image data
  const clearPage = () => {
    setParseError("");
    // setSopInstanceUid("");
    // setPatientId("");
    // setOtherPatientIds("");
  };

  // Function used to parse the data
  const parseByteArray = (byteArray) => {
    // We need to setup a try/catch block because parseDicom will throw an exception
    // if you attempt to parse a non dicom part 10 file (or one that is corrupted)
    try {
      // parse byteArray into a DataSet object using the parseDicom library
      var dataSet = dicomParser.parseDicom(byteArray);

      var sopInstanceUid = dataSet.string("x0020000d");
      // Set data to state variable
      setSopInstanceUid(sopInstanceUid);

      var patientId = dataSet.string("x00100010");
      if (patientId !== undefined) {
        // Set data to state variable
        setPatientId(patientId);
        console.log(patientId);
      } else {
        alert("element has no data");
      }
      var otherPatientIds = dataSet.string("x0008103e");
      if (otherPatientIds !== undefined) {
        // Set data to state variable
        setOtherPatientIds(otherPatientIds);
        console.log(otherPatientIds);
      } else {
        // Set data to state variable
        setOtherPatientIds("element not present");
      }
    } catch (err) {
      // we catch the error and display it to the user
      // Set data to state variable
      setParseError(err);
    }
  };

  // load the file dropped on the element and then call parseByteArray with a
  // Uint8Array containing the files contents
  const loadFile = (acceptedFiles, callback) => {
    // console.log(acceptedFiles);
    const folderList = [],
      patientNameList = [];

    for (var i = 0, len = acceptedFiles.length; i < len; i++) {
      var file = acceptedFiles[i];
      var reader = new FileReader();

      reader.onload = (function (f) {
        return function (e) {
          // Here you can use `e.target.result` or `this.result`
          // and `f.name`.
          // console.log(`f.name : ${f.name}`);
          // console.log(`file.name : ${file.name}`);
          // console.log(`e.target.result : ${e.target.result}`);
          var arrayBuffer = e.target.result;
          var byteArray = new Uint8Array(arrayBuffer);
          var dataSet = dicomParser.parseDicom(byteArray);

          var folderName = dataSet.string("x0008103e");
          var patientName = dataSet.string("x00100010");
          folderList.push(folderName);
          patientNameList.push(patientName);
          // console.log(folderName);
          setFolderNameList([...new Set(folderList)]);
          if (!dcomFileList[patientName]) {
            dcomFileList[patientName] = {};
          }
          if (!dcomFileList[patientName][folderName]) {
            dcomFileList[patientName][folderName] = [];
          }
          dcomFileList[patientName][folderName] = [
            ...dcomFileList[patientName][folderName],
            f,
          ];
          setDcomFileList(dcomFileList);
          if (!--len) callback(dcomFileList);
        };
      })(file);

      reader.readAsArrayBuffer(file);
    }
  };
  const folderListDisplay = () => {
    console.log(folderNameList);
    console.log(dcomFileList);
    // onSelectFolder(folderNameList);
  };
  // Displaying the drop upload feature and the DisplayData component. Passing the image data as a object.
  return (
    <div style={textStyle}>
      <div className="column" id="dropZone">
        <div className="col-md-12" {...getRootProps()}>
          <form id="form-file-upload">
            <input id="input-file-upload" {...getInputProps()} />
            <label id="label-file-upload" htmlFor="input-file-upload">
              <div>
                {isDragActive ? (
                  <p>Drop the folder here ...</p>
                ) : (
                  <p>Please drag & drop the Dicom folder here or</p>
                )}
                <button className="upload-button">Upload folder</button>
              </div>
            </label>
          </form>
          {/* <DisplayData
            image={{ parseError, sopInstanceUid, patientId, otherPatientIds }}
          /> */}
        </div>
      </div>
    </div>
  );
};

// Export functional components
export default DicomHeader;
