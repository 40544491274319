import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: { feedbackList: [] },
  reducers: {
    setFeedbackList: (state, action) => {
      console.log(action.payload);
      state.feedbackList = action.payload;
    },
  },
});

export const { setFeedbackList } = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const selectFeedbackList = (state) => state.feedback.feedbackList;
