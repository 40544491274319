import { createSlice } from "@reduxjs/toolkit";

const patientsSlice = createSlice({
  name: "patients",
  initialState: { patientsList: null },
  reducers: {
    setPatientsList: (state, action) => {
      console.log(action.payload);
      state.patientsList = action.payload;
    },
  },
});

export const { setPatientsList } = patientsSlice.actions;

export default patientsSlice.reducer;

export const selectPatientsList = (state) => state.patients.patientsList;
