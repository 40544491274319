import React from "react";
// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   CardFooter,
//   Table,
//   Row,
//   Col,
// } from "reactstrap";

// core components
import PanelHeader from "../components/PanelHeader/PanelHeader.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Footer from "../components/Footer/Footer";
import AdminNavbar from "../components/Navbars/AdminNavbar";

const ContactUsPage = (props) => {
  return (
    <div className="wrapper">
      <Sidebar />
      <div className="main-panel">
        <AdminNavbar {...props} />
        <PanelHeader size="sm" />
        <div className="content"></div>
        {
          // we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  );
};

export default ContactUsPage;
