import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../features/auth/authSlice";
import { useLoginMutation } from "../features/auth/authApiSlice";
import usePersist from "../hooks/usePersist";
import useTitle from "../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import AuthNavbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footer/Footer";
// core components
import nowLogo from "../assets/img/now-logo.png";

import bgImage from "../assets/img/bg14.jpg";

const LoginPage = () => {
  useTitle("Employee Login");

  const [firstnameFocus, setfirstnameFocus] = useState(false);
  const [lastnameFocus, setlastnameFocus] = useState(false);
  const [filterColor, setFilterColor] = useState("yellow");

  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  useEffect(() => {
    userRef.current.focus();
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { accessToken } = await login({ username, password }).unwrap();
      dispatch(setCredentials({ accessToken }));
      setUsername("");
      setPassword("");
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      navigate("/client/dashboard");
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
      errRef.current.focus();
    }
  };

  const handleUserInput = (e) => setUsername(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);
  const errClass = errMsg ? "errmsg" : "offscreen";

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  return (
    <>
      <AuthNavbar />
      <p
        ref={errRef}
        className={errClass}
        aria-live="assertive"
        style={{ display: "none" }}
      >
        {errMsg}
      </p>

      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color={filterColor}>
          <div className="content">
            <div className="login-page">
              <Container>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  <Form>
                    <Card className="card-login card-plain">
                      <CardHeader>
                        <div className="logo-container">
                          <img src={nowLogo} alt="now-logo" />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            "no-border form-control-lg " +
                            (firstnameFocus ? "input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            id="username"
                            ref={userRef}
                            value={username}
                            onChange={handleUserInput}
                            autoComplete="off"
                            required
                            // onFocus={(e) => setfirstnameFocus(true)}
                            // onBlur={(e) => setfirstnameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border form-control-lg " +
                            (lastnameFocus ? "input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            id="password"
                            onChange={handlePwdInput}
                            value={password}
                            required
                          />
                        </InputGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              id="persist"
                              onChange={handleToggle}
                              checked={persist}
                            />
                            <span className="form-check-sign" />
                            <div style={{ color: "white" }}>
                              Trust This Device
                              {/* <a href="#something">Trust This Device</a>. */}
                            </div>
                          </Label>
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          color="primary"
                          size="lg"
                          href="#pablo"
                          className="mb-3 btn-round"
                          onClick={handleSubmit}
                        >
                          Login
                        </Button>
                        <div className="pull-left">
                          <h6>
                            <a href="#pablo" className="link footer-link">
                              Create Account
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right">
                          <h6>
                            <a href="#pablo" className="link footer-link">
                              Need Help?
                            </a>
                          </h6>
                        </div>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
