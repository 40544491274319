import { apiSlice } from "../../app/api/apiSlice";
import { setFeedbackList } from "./feedbackSlice";

export const feedbackApiSlice = apiSlice.injectEndpoints({
  reducerPath: "feedbacksList",
  endpoints: (builder) => ({
    getFeedbacks: builder.query({
      query: (userId) => ({
        url: `/feedbacks/${userId}`,
      }),
      providesTags: ["Feedback"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          const { feedbackList } = data;
          dispatch(setFeedbackList(feedbackList));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getFeedbackById: builder.query({
      query: (userId) => ({
        url: `/feedbacks/${userId}`,
      }),
      providesTags: ["Feedback"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          const { feedbackList } = data;
          dispatch(setFeedbackList(feedbackList));
        } catch (err) {
          console.log(err);
        }
      },
    }),

    createFeedback: builder.mutation({
      query: ({userId, comments, functionality, errorType}) => ({
        url: `/feedbacks`,
        method: "POST",
        body: {userId, comments, functionality, errorType },
      }),
      invalidatesTags: ["Feedback"],
    }),

    deleteFeedback: builder.mutation({
      query: (feedbackIds) => ({
        url: `/feedbacks`,
        method: "DELETE",
        body: { feedbackIds },
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const {
  useGetFeedbacksQuery,
  useGetFeedbackByIdQuery,
  useDeleteFeedbackMutation,
  useCreateFeedbackMutation
} = feedbackApiSlice;
