import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import useAuth from "../hooks/useAuth";
import useTitle from "../hooks/useTitle";
// core components
import PanelHeader from "../components/PanelHeader/PanelHeader.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Footer from "../components/Footer/Footer";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import DicomHeader from "../components/Dicom/DicomHeader";
import {
  useGetPatientsQuery,
  useUploadDicomZipMutation,
} from "../features/patients/patientsApiSlice";
import {
 useCreateFeedbackMutation
} from "../features/feedback/feedbackApiSlice.js";
import { PulseLoader } from "react-spinners";
import PatientsCard from "../components/Cards/PatientsCard";
import Spinner from "../components/Spinner/Spinner";


const baseUrl = "https://api-neuronorm0.herokuapp.com/patients";
const myList = {};
let _patientList = [];
let _dicomList = [];
const zip = new JSZip();
const projectName =
  Date.now().toString(18) + Math.random().toString(18).substring(18);
const project = zip.folder(projectName);

const DashboardPage = (props) => {
  const { username, isManager, isAdmin, userId, dcmjson } = useAuth();
  const [currentProgress, setCurrentProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(80);
  const [intervalTime, setIntervalTime] = useState(1000);
  const notificationAlert = useRef();
  // Simulate upload progress increase over time
  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (currentProgress < totalProgress) {
        setCurrentProgress(currentProgress + 1);
      } else {
        clearInterval(progressInterval);
      }
    }, intervalTime); // Adjust the interval time as needed

    return () => {
      clearInterval(progressInterval);
    };
  }, [currentProgress]);

  useTitle(`Neuronorms: ${username}`);
  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetPatientsQuery(userId);

  const [ createFeedback ] = useCreateFeedbackMutation();

  const [
    uploadDicomZip,
    { isSuccess: isUploadSuccess, isError: isUploadError, error: uploaderror },
  ] = useUploadDicomZipMutation();
  // const errRef = useRef();
  const [pageSubcategories, setpageSubcategories] = useState("ps1");
  const [patientNameList, setPatientNameList] = useState([]);
  // const [patientIds, setPatientIds] = useState([]);
  const [seriesList, setSeriesList] = useState({});
  const [dcomFileList, setDcomFileList] = useState({});
  const [selectedList, setSelectedList] = useState({});
  const [patientNameDom, setPatientNameDom] = useState();
  const [patientDataUploaded, setPatientDataUploaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [landing, setLanding] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [commentsState, setCommentsState] = useState("");
  const [comments, setComments] = useState("");
  const [functionality, setFunctionality] = useState("");
  const [errorType, setErrorType] = useState("");


  const handleFunctionalityChange = (event) => {
    setFunctionality(event.target.value);
  };
  const handleErrorChange = (event) => {
    setErrorType(event.target.value);
  };

  const notify = (place, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
          Thank you for the feedback!
          We get back to you soon!
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const submitFeedback = async () => {
    toggleFeedback()
    if (commentsState === "") {
        setCommentsState("has-danger");
        return
      }
      console.log(userId)
      await createFeedback({userId, comments, functionality, errorType})
      console.log(comments, functionality, errorType)
      notify("tr", 2)

  };
  const [uploadStatus, setUploadStatus] = useState({
    isSorted: false,
    isUpload: false,
  });

  const toggleFeedback = () => {
    setFeedback(!feedback);
  };
  const toggleLanding = () => {
    setLanding(!landing);
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const uploadFileStatus = (status) => {
    setUploadStatus({ ...status });
  };
  const handleSelectFolder = (patientList, fileList) => {
    console.log(fileList);
    console.log(patientList);

    setPatientNameList([...patientList]);
    patientList.map((patient) => {
      myList[patient] = [];
      console.log(myList);
    });
    setSelectedList(myList);
    setDcomFileList(fileList);
    setUploadStatus({ isSorted: false, isUpload: false });
  };
  const onSelectSeries = (e, patientName) => {
    console.log(patientName, e.target.value);
    myList[patientName].push(e.target.value);
    console.log(myList);
  };
  const createZip = async () => {
    let setPatientIds = [];
    // let files = acceptedFiles;
    // const imgs = zip.folder("images");
    // const img = imgs.folder("images1");
    // for (let file = 0; file < patientNameList.length; file++) {
    //   let sub = patientNameList[file];
    //   let p = project.folder(sub);
    //   for (let f = 0; f < myList[sub].length; f++) {
    //     p.folder(myList[sub][f]);
    //   }
    // img.file(files[file].name, files[file]);
    setUploadStatus({ isSorted: false, isUpload: true });

    patientNameList.map((patientName, index) => {
      if (myList[patientName]) {
        let pList = `${patientName.replaceAll(" ", "")}_${
          Date.now().toString(18) + Math.random().toString(18).substring(16)
        }`;
        let p = project.folder(pList);
        setPatientIds.push(pList);
        // let p = project.folder(patientName);
        Object.keys(dcomFileList[patientName]).map((seriesName, index) => {
          if (myList[patientName].includes(seriesName)) {
            let s = p.folder(seriesName);
            // console.log(dcomFileList[patientName][seriesName]);
            dcomFileList[patientName][seriesName].map((fileName, index) => {
              s.file(fileName.name, fileName);
              console.log(fileName.name);
            });
          }
        });
      }
    });

    zip.generateAsync({ type: "blob" }).then(async (content) => {
      // saveAs(content, "example.zip");
      try {
        setIntervalTime(Math.floor(content.size / 100000));
        console.log(setPatientIds);
        let dateTime = Date.now().toString();
        const data = {
          userId,
          patientsName: patientNameList.join(";"),
          patientsIds: setPatientIds.join(";"),
          dateTime,
        };
        // let rootPath = `advv_${patientNameList.length.toString()}_${Date.now().toString()}`;
        // let filename = `${rootPath}`;

        // const patientsList = patientsName.split(";");
        // const pIds = patientsIds.split(";");
        let rootPath = `${userId}_${patientNameList.length.toString()}_${dateTime}`;

        var formData = new FormData();
        formData.append("file", content);
        // formData.append("file", content);
        // formData.append("userId", userId);
        // formData.append("patientsName", patientNameList.join(";"));
        // formData.append("patientsIds", setPatientIds.join(";"));
        console.log(setPatientIds);

        setCurrentProgress(currentProgress + 1);
        const { url } = await fetch(`${baseUrl}/s3Url/${rootPath}`).then(
          (res) => res.json()
        );
        console.log(url);
        console.log(formData);
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/zip",
          },
          body: content,
        });

        console.log(response);

        await uploadDicomZip(data);
        setTotalProgress(100);
        setCurrentProgress(currentProgress + 1);
        setPatientDataUploaded(true);
        setUploadStatus({ isSorted: false, isUpload: false });
      } catch (err) {
        if (!err.status) {
          setErrMsg("No Server Response");
        } else if (err.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg(err.data?.message);
        }
        // errRef.current.focus();
      }
    });
  };

  const uploadDcomFile = async () => {
    await createZip();
    // setPatientDataUploaded(true);
    // setUploadStatus({ isSorted: false, isUpload: false });
  };

  useEffect(() => {
    console.log(data["message"]);
    console.log(dcmjson);
    const landingPage = localStorage.getItem('landingPage');
      if (landingPage === 'TRUE') {
        setLanding(false);
      }else {
        setLanding(true);
        localStorage.setItem('landingPage', "TRUE");
      }
  }, [data]);
  useEffect(() => {
    console.log("[]");
    setPatientDataUploaded(false);
  }, []);

  return (
    <div>
      <NotificationAlert ref={notificationAlert} />
    <div className="wrapper">
      <Sidebar />
      <div className="main-panel">
        <AdminNavbar {...props} />
        <PanelHeader size="sm" />

        <div className="content">
      <Modal isOpen={landing}
              toggle={toggleLanding}
              className="text-center"
              centered={true}
              size="xl">
      <ModalHeader className="justify-content-center uppercase title"
                    toggle={toggleLanding}
                      tag="h4">
          Welcome to NeuroNorm Plateform
      </ModalHeader>
      <ModalBody>
      <div className="container">
        <h1>NeuroNorm Platform</h1>

        <p>This platform preprocesses, reconstructs, and performs Fiber
          Tractography on Diffusion MR Imaging datasets.</p>

        <div className="section-heading">User Instructions:</div>
        <p className="user-instructions">
            - Upload DICOM datasets for patients. The patient will be anonymized and stored in NeuroNorm Cloud. Users can
            delete all data if necessary after processing.
            <br/>
            - The user should upload a T1/T2/FLAIR/MPRAGE with diffusion MRI scan with at least 15 gradient directions.
            If you want a better result and want to update your protocol, please refer to our FAQ section or contact
            us.
        </p>

        <div className="section-heading">Processing Pipeline:</div>
        <p>
            Our automated processing pipeline processes, reconstructs the diffusion model, and segments 72 Fiber tracts.
            Once processing is done, the user will be notified, and the visualizer icon will be green; data will be ready
            to be visualized on our web platform.
        </p>

        <div className="section-heading">Visualization:</div>
        <p>
            Derived diffusion MRI volumes and MPRAGE/T1, T2, and FLAIR images will be visualized as underlay with fiber
            tracts.
        </p>

        <div className="development-phase">
            This platform is still in the development phase. We appreciate your feedback to improve user experiences.
        </div>
    </div>
      </ModalBody>
                      {/* <ModalFooter>
                        <Button color="default">Nice Button</Button>
                        <Button color="danger" onClick={toggleLanding}>
                          Close
                        </Button>
                      </ModalFooter> */}
                    </Modal>
          <Row>
            <PatientsCard
              count={data.patientCount}
              resultType="Total Patient"
              cls="icon icon-primary icon-circle"
              icons="now-ui-icons business_badge"
            />
            <PatientsCard
              count={data.patientsAnalyzed}
              resultType="Analyzed"
              cls="icon icon-warning icon-circle"
              icons="now-ui-icons health_ambulance"
            />
            <PatientsCard
              count={data.patientsProgress}
              resultType="In Progress"
              cls="icon icon-danger icon-circle"
              icons="now-ui-icons sport_user-run"
            />
          </Row>
          {/* <Row><Button onClick={toggleLanding}>Click Here</Button></Row> */}
          <Row>
            <Col className="text-center" lg="8" md="12">
              <Card className="card-contributions">
                <CardBody
                  style={{ padding: 0 }}
                  className={uploadStatus.isSorted ? "disabledDiv" : ""}
                >
                  <CardTitle tag="h5">
                    Upload a New Brain Imaging Dataset
                  </CardTitle>
                  <FormGroup controlid="formFile" className="mb-1">
                    <div>
                      <DicomHeader
                        onSelectFolder={handleSelectFolder}
                        onUploadFileStatus={uploadFileStatus}
                      />
                    </div>
                  </FormGroup>
                </CardBody>
                <hr />
                <CardFooter>
                  <Row>
                    {/* <Col xs="6">
                      <div className="card-stats justify-content-center">
                        <Switch offColor="" onColor="" />
                        <span>All public contributions</span>
                      </div>
                    </Col> */}
                    <Col xs="12">
                      {/* <p>Uploading...</p> */}
                      <div className="card-stats justify-content-center">
                        {/* <Switch defaultValue={false} offColor="" onColor="" /> */}
                        {uploadStatus.isSorted && (
                          <PulseLoader color={"#4b9cdb"} />
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
              {Object.keys(dcomFileList).length !== 0 && (
                <Card className="card-plain card-subcategories">
                  <CardHeader>
                    <CardTitle tag="h4" className="text-center">
                      Patient List
                    </CardTitle>
                    <br />
                  </CardHeader>
                  <CardBody>
                    <Nav
                      pills
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                    >
                      {patientNameDom}
                      {Object.keys(dcomFileList).map((patientName, index) => (
                        <NavItem key={index}>
                          <NavLink
                            className={
                              pageSubcategories === `ps${index + 1}`
                                ? "active"
                                : "tab-not-active"
                            }
                            onClick={() =>
                              setpageSubcategories(`ps${index + 1}`)
                            }
                          >
                            {patientName}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={pageSubcategories}
                    >
                      {Object.keys(dcomFileList).map((patientName, index) => (
                        <TabPane key={index} tabId={`ps${index + 1}`}>
                          <CardBody style={{ paddingBottom: 0 }}>
                            <Table responsive>
                              <tbody>
                                {Object.keys(dcomFileList[patientName]).map(
                                  (seriesName, index) => (
                                    <tr key={index}>
                                      <td>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              defaultChecked={false}
                                              type="checkbox"
                                              defaultValue={seriesName}
                                              onChange={(e) =>
                                                onSelectSeries(e, patientName)
                                              }
                                            />
                                            <span className="form-check-sign" />
                                          </Label>
                                        </FormGroup>
                                      </td>
                                      <td className="text-left">
                                        {seriesName}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </CardBody>
                        </TabPane>
                      ))}
                      {/* {uploadStatus.isUpload && <Spinner />} */}
                      {uploadStatus.isUpload && (
                        <Progress
                          animated
                          color="success"
                          striped
                          value={currentProgress}
                        />
                      )}
                    </TabContent>
                  </CardBody>
                  <CardFooter className=" text-right">
                    <Button
                      className="btn-round"
                      disabled={!uploadStatus.isUpload}
                      style={{ fontSize: "16px" }}
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Reset
                    </Button>
                    <Button
                      color="info"
                      disabled={uploadStatus.isUpload}
                      className="upload-button-style btn-round"
                      onClick={(e) => uploadDcomFile()}
                    >
                      <i className="now-ui-icons arrows-1_cloud-upload-94 upload-button-style-i" />
                      Upload Patient Data
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </Col>
            <Col md="4" style={{ marginTop: "100px" }}>
              <Card className="card-timeline card-plain">
                <CardBody>
                  <ul className="timeline timeline-simple">
                    <li
                      className="timeline-inverted"
                      style={{ marginBottom: "200px" }}
                    >
                      <div className="timeline-badge info">
                        <i className="now-ui-icons arrows-1_cloud-upload-94" />
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <Badge color="info">Step 1</Badge>
                        </div>
                        <div className="timeline-body">
                          <p>
                            Please Drag & Drop the folder contains DCOM file of
                            patients
                          </p>
                        </div>
                      </div>
                    </li>
                    {Object.keys(dcomFileList).length !== 0 && (
                      <li className="timeline-inverted">
                        <div className="timeline-badge warning">
                          <i className="now-ui-icons design_bullet-list-67" />
                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <Badge color="warning">Step 2</Badge>
                          </div>
                          <div className="timeline-body">
                            <p>
                              Select the patient name to get the list of sorted
                              DCOM file, chose the file which you want to
                              upload.
                            </p>
                          </div>
                        </div>
                      </li>
                    )}
                    {patientDataUploaded && (
                      <li className="timeline-inverted">
                        <div className="timeline-badge success">
                          <i className="now-ui-icons ui-2_like" />
                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <Badge color="success">Step 3</Badge>
                          </div>
                          <div className="timeline-body">
                            <p>
                              Thanks for uploading..!. You will get notified
                              once data processed & ready to view
                            </p>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {
          // we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
    <Modal isOpen={feedback}
              toggle={toggleFeedback}
              centered={true}
              size="lg">
      <ModalHeader className="justify-content-center uppercase title"
                    toggle={toggleFeedback}
                      tag="h4">
          Your feedback is precious to US
      </ModalHeader>
      <ModalBody>
                <Form action="#" method="#">
                  <label className="form-labels" for="functionality">
                    Select The Functionality - (Type)</label>
                  <FormGroup>

                  <Input value={functionality} onChange={handleFunctionalityChange} id="functionality" name="functionality" type="select" className="form-select">
                    <option selected>General</option>
                    <option>Upload</option>
                    <option>Signup</option>
                    <option>Visualizer</option>
                    <option>Others</option>
                  </Input>
                  </FormGroup>
                  <label className="form-labels" for="error-type">Select Error - (Type)</label>
                  <FormGroup>
                  <Input value={errorType} onChange={handleErrorChange} id="error-type" name="error-type" type="select" className="form-select">
                    <option selected> Not working</option>
                    <option>Missing something</option>
                    <option>Don't Like It</option>
                    <option>Need Improvement</option>
                    <option>Others</option>
                  </Input>
                  </FormGroup>
                  <FormGroup  className={`has-label ${commentsState}`}>
                    <Label  className="form-labels" for="comments">
                      Comments
                    </Label>
                    <Input
                      id="comments"
                      name="comments"
                      type="textarea" className="form-comment"
                      placeholder="Describe the comments here.."
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 10)) {
                          setCommentsState("has-danger");
                        } else {
                          setCommentsState("has-success");
                        }
                        setComments(e.target.value);
                      }}
                    />
                  </FormGroup>
                     {commentsState === "has-danger" ? (
                      <label className="error" style={{display: 'inline-block !important'}}>
                        This field is required & atleast 10 character.</label>
                    ) : null}
                </Form>
      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={toggleFeedback}>Cancel</Button>
                        <Button color="success" onClick={submitFeedback}>
                          Submit
                        </Button>
                      </ModalFooter>
                    </Modal>
    <div style={{ position: 'fixed', bottom: '35px', right: '20px' }}>
      <Button onClick={toggleFeedback} color="primary" className="fab" style={{ borderRadius: '50%', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        {/* Add your FAB icon or content here */}
        <div class="font-icon-detail">
                      <i class="now-ui-icons ui-2_chat-round" style={{ fontSize: '25px !important' }}></i>
                      {/* <p>ui-2_chat-round</p> */}
                    </div>
      </Button>
    </div>
    </div>
  );
};

export default DashboardPage;
