import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import { Button, UncontrolledTooltip } from "reactstrap";

// analysisStatus: "Passed";
// completed: true;
// createdAt: "2022-09-25T07:52:05.247Z";
// dicomBasePath: "https://neuronorm0.s3.us-east-2.amazonaws.com/neuronorms-dicom-upload";
// dicomRootPath: "USER_PCOUNT_PFIRSTLETTER_INPUT";
// dicomUploaded: "2022-09-25T07:21:39.723Z";
// patientName: "KUMAR H";
// qaCheck: "Progress";
// report: "KUMAR_H_R1";
// trackViz: "KUMAR_H_T1";
// trkBasePath: "https://neuronorm0.s3.us-east-2.amazonaws.com/neuronorms-dicom-upload";
// trkRootPath: "USER_PCOUNT_PFIRSTLETTER_OUTPUT";
// trkUploaded: "2022-09-25T07:37:18.678Z";
// updatedAt: "2022-09-25T07:52:05.247Z";
// userId: "631cbf345d51f6556bb81ed3";
const Passed = () => (
  <Button color="success" size="sm">
    Passed
  </Button>
);
const Failed = () => (
  <Button color="danger" size="sm">
    Failed
  </Button>
);
const Progress = () => (
  <Button color="primary" size="sm">
    Progress
  </Button>
);

const openInNewTab = (trkBasePath, trkRootPath, patientsIds) => {
  // tractseg_output, TOM_trackings
  const userTrkPath = `${trkBasePath}/${trkRootPath}/${patientsIds}`;
  // const userTrkPath = `${trkBasePath}/${trkRootPath}/${patientsIds}/tractseg_output/TOM_trackings/`;
  window.open(
    // `http://localhost:3000/trkviewer/?url=${userTrkPath}`,
    `https://trkviewer.netlify.app/?url=${userTrkPath}`,
    "targetWindow",
    "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
  );
};
const PatientsList = (props) => (
  <tr
    style={{ backgroundColor: (props.index + 1) % 2 === 0 ? "#bbe3ff42" : "" }}
  >
    <td className="text-center">{props.index + 1}</td>
    <td>{props.patientName}</td>
    <td>{new Date(props.dicomUploaded).toGMTString()}</td>
    <td className="text-center">
      {props.qaCheck === "Passed" ? (
        <Passed />
      ) : props.qaCheck === "Failed" ? (
        <Failed />
      ) : (
        <Progress />
      )}
    </td>
    <td className="text-right">
      {props.analysisStatus === "Passed" ? (
        <Passed />
      ) : props.analysisStatus === "Failed" ? (
        <Failed />
      ) : (
        <Progress />
      )}
    </td>
    <td className="text-right btns-mr-5">
      <Button
        className="btn-icon"
        color="info"
        id={`tooltip_${props.index + 1}`}
        size="sm"
        type="button"
        disabled={!props.completed}
        onClick={() =>
          openInNewTab(props.trkBasePath, props.trkRootPath, props.patientsIds)
        }
      >
        <i className="fas fa-brain table-icon viz"></i>
      </Button>
      <UncontrolledTooltip delay={0} target={`tooltip_${props.index + 1}`}>
        Visualizer
      </UncontrolledTooltip>
      <Button
        className="btn-icon"
        color="success"
        id={`tooltip_rep_${props.index + 100000}`}
        size="sm"
        type="button"
        disabled={!props.completed}
      >
        <i className="now-ui-icons files_paper" />
      </Button>
      <UncontrolledTooltip
        delay={0}
        target={`tooltip_rep_${props.index + 100000}`}
      >
        Report
      </UncontrolledTooltip>
    </td>
    <td className="text-right">
      <Button
        disabled={!props.isDelete}
        color="danger"
        size="sm"
        onClick={() => props.deletePatientById(props.patientsIds)}
      >
        Delete
      </Button>
    </td>
  </tr>
);

export default PatientsList;
