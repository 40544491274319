// import { Link } from "react-router-dom";

// const Public = () => {
//   const content = (
//     <section className="public">
//       <header>
//         <h1>Welcome to Repair Store!</h1>
//       </header>
//       <main>
//         <p>
//           Located in Beautiful Downtown Foo City, Repair Store provides a
//           trained staff ready to meet your repair needs.
//         </p>
//         <p>&nbsp;</p>
//         <address>
//           Repair Store
//           <br />
//           555 Foo Drive
//           <br />
//           Foo City, CA 12345
//           <br />
//           <a href="tel:+15555555555">(555) 555-5555</a>
//         </address>
//       </main>
//       <footer>
//         <Link to="/login">Employee Login</Link>
//       </footer>
//     </section>
//   );
//   return content;
// };
// export default Public;

import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footer/Footer";
// core components
import avatar from "../assets/img/emilyz.jpg";

import bgImage from "../assets/img/bg13.jpg";

const PublicPage = () => {
  React.useEffect(() => {
    document.body.classList.add("lock-page");
    return function cleanup() {
      document.body.classList.remove("lock-page");
    };
  }, []);
  return (
    <>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color="yellow">
          <div className="content">
            <div className="lock-page">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={10} md={8} xs={12}>
                    <div className="info-area info-horizontal mt-5">
                      {/* <div className="icon icon-primary">
                        <i className="now-ui-icons media-2_sound-wave" />
                      </div> */}
                      <div className="description">
                        <h1 className="info-title">NEURONORM</h1>
                        <p className="description">
                        Revolutionize Neuroimage Analysis and Diagnosis!
                        </p>
                      </div>
                    </div>
                    {/* <div className="info-area info-horizontal">
                      <div className="icon icon-primary">
                        <i className="now-ui-icons media-1_button-pause" />
                      </div>
                      <div className="description">
                        <h1 className="info-title">Fully Coded in React 16</h1>
                        <p className="description">
                          We've developed the website with React 16, HTML5 and
                          CSS3. The client has access to the code using GitHub.
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="info-area info-horizontal">
                      <div className="icon icon-info">
                        <i className="now-ui-icons users_single-02" />
                      </div>
                      <div className="description">
                        <h1 className="info-title">Built Audience</h1>
                        <p className="description">
                          There is also a Fully Customizable CMS Admin Dashboard
                          for this product.
                        </p>
                      </div>
                    </div> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default PublicPage;
