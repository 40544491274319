import { Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/dashboard.page";
import Layout from "./components/Layout";
import PatientPage from "./pages/patient.page";
import ContactUsPage from "./pages/contact.page";
import FaqPage from "./pages/faq.page";
import LoginPage from "./pages/login.page";
import PublicPage from "./components/Public";
import RegisterPage from "./pages/register.page";
import RequireAuth from "./features/auth/RequireAuth";

// import Welcome from './features/auth/Welcome'
// import NotesList from './features/notes/NotesList'
// import UsersList from './features/users/UsersList'
// import EditUser from './features/users/EditUser'
// import NewUserForm from './features/users/NewUserForm'
// import EditNote from './features/notes/EditNote'
// import NewNote from './features/notes/NewNote'
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import { useDispatch } from "react-redux";
import { setCredentials } from "./features/auth/authSlice";
import { ROLES } from "./config/roles";
import useTitle from "./hooks/useTitle";

function App() {
  useTitle("Neuro Norms || Brain");
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    dispatch(setCredentials({ accessToken }));
  }
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<PublicPage />} />
        <Route path="auth/login-page" element={<LoginPage />} />
        <Route path="auth/register-page" element={<RegisterPage />} />
        {/* protected routes */}
        {/* <Route element={<RequireAuth />}>
          <Route path="client/dashboard" element={<DashboardPage />} />
          <Route path="client/patients-list" element={<PatientPage />} />
          <Route path="client/contactus" element={<ContactUsPage />} />
          <Route path="client/faq" element={<FaqPage />} />
        </Route> */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
          >
            <Route element={<Prefetch />}>
              <Route path="client/dashboard" element={<DashboardPage />} />
              <Route path="client/patients-list" element={<PatientPage />} />
              <Route path="client/contactus" element={<ContactUsPage />} />
              <Route path="client/faq" element={<FaqPage />} />

              {/* <Route index element={<Welcome />} /> */}

              {/* <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewUserForm />} />
                  </Route>
                </Route> */}

              {/* <Route path="notes">
                  <Route index element={<NotesList />} />
                  <Route path=":id" element={<EditNote />} />
                  <Route path="new" element={<NewNote />} />
                </Route> */}
            </Route>
            {/* End Dash */}
          </Route>
        </Route>
        {/* End Protected Routes */}
      </Route>
    </Routes>
  );
}

export default App;
