import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
// core components
import PanelHeader from "../components/PanelHeader/PanelHeader.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Footer from "../components/Footer/Footer";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import {
  useCreateFeedbackMutation
 } from "../features/feedback/feedbackApiSlice.js";
import {
  selectPatientsList,
  setPatientsList,
} from "../features/patients/patientsSlice.js";
import PatientsList from "../features/patients/PatientsList.js";
import { useDeletePatientMutation } from "../features/patients/patientsApiSlice.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const PatientPage = (props) => {
  const notificationAlert = useRef();
  const [ createFeedback ] = useCreateFeedbackMutation();
  const { username, isManager, isAdmin, userId, dcmjson } = useAuth();
  const [patientsRecord, setPatientsRecord] = useState([]);
  const dispatch = useDispatch();
  const patients = useSelector(selectPatientsList);
  const [feedback, setFeedback] = useState(false);
  const [commentsState, setCommentsState] = useState("");
  const [comments, setComments] = useState("");
  const [functionality, setFunctionality] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleFunctionalityChange = (event) => {
    setFunctionality(event.target.value);
  };
  const handleErrorChange = (event) => {
    setErrorType(event.target.value);
  };

  const notify = (place, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
          Thank you for the feedback!
          We get back to you soon!
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const submitFeedback = async () => {
    toggleFeedback()
    if (commentsState === "") {
        setCommentsState("has-danger");
        return
      }
      console.log(userId)
      await createFeedback({userId, comments, functionality, errorType})
      console.log(comments, functionality, errorType)
      notify("tr", 2)

  };
  const toggleFeedback = () => {
    setFeedback(!feedback);
  };
    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
      if (value.length >= length) {
        return true;
      }
      return false;
    };
  useEffect(() => {
    if (!patients) {
      navigate("/client/dashboard");
    } else {
      setPatientsRecord(patients);
      console.log(patients);
    }
  }, [patients]);
  const [
    deletePatient,
    { isSuccess: isUploadSuccess, isError: isUploadError, error: uploaderror },
  ] = useDeletePatientMutation();
  // const { getPatient } = useGetPatientQuery();
  const deletePatientById = async (patientId) => {
    // alert(patientId);
    try {
      await deletePatient(patientId);
      let filteredUsers = patientsRecord.filter(
        (patientsRecord) => patientsRecord.patientsIds !== patientId
      );
      dispatch(setPatientsList(filteredUsers));
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
    }
  };

  return (
    <div className="wrapper">
       <NotificationAlert ref={notificationAlert} />
      <Sidebar />
      <div className="main-panel">
        <AdminNavbar {...props} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Patient List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead
                      className="text-primary"
                      style={{ backgroundColor: "#dee2e6" }}
                    >
                      <tr>
                        <th className="text-center">#</th>
                        <th>Name</th>
                        <th>Uploaded</th>
                        <th className="text-center">QA Check</th>
                        <th className="text-right">Analysis</th>
                        <th className="text-right">Report/Viz</th>
                        <th className="text-right">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientsRecord.map((patient, index) => (
                        <PatientsList
                          key={index}
                          {...patient}
                          index={index}
                          deletePatientById={deletePatientById}
                        />
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal isOpen={feedback}
              toggle={toggleFeedback}
              centered={true}
              size="lg">
      <ModalHeader className="justify-content-center uppercase title"
                    toggle={toggleFeedback}
                      tag="h4">
          Your feedback is precious to US
      </ModalHeader>
      <ModalBody>
      {/* <Card> */}
              {/* <CardHeader>
                <CardTitle tag="h4">Stacked Form</CardTitle>
              </CardHeader>
              <CardBody> */}
                <Form action="#" method="#">
                  <label className="form-labels" for="functionality">
                    Select The Functionality - (Type)</label>
                  <FormGroup>

                  <Input value={functionality} onChange={handleFunctionalityChange} id="functionality" name="functionality" type="select" className="form-select">
                    <option selected>General</option>
                    <option>Upload</option>
                    <option>Signup</option>
                    <option>Visualizer</option>
                    <option>Others</option>
                  </Input>
                  </FormGroup>
                  <label className="form-labels" for="error-type">Select Error - (Type)</label>
                  <FormGroup>
                  <Input value={errorType} onChange={handleErrorChange} id="error-type" name="error-type" type="select" className="form-select">
                    <option selected> Not working</option>
                    <option>Missing something</option>
                    <option>Don't Like It</option>
                    <option>Need Improvement</option>
                    <option>Others</option>
                  </Input>
                  </FormGroup>
                  <FormGroup  className={`has-label ${commentsState}`}>
                    <Label  className="form-labels" for="comments">
                      Comments
                    </Label>
                    <Input
                      id="comments"
                      name="comments"
                      type="textarea" className="form-comment"
                      placeholder="Describe the comments here.."
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 10)) {
                          setCommentsState("has-danger");
                        } else {
                          setCommentsState("has-success");
                        }
                        setComments(e.target.value);
                      }}
                    />
                  </FormGroup>
                     {commentsState === "has-danger" ? (
                      <label className="error" style={{display: 'inline-block !important'}}>
                        This field is required & atleast 10 character.</label>
                    ) : null}
                </Form>
      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={toggleFeedback}>Cancel</Button>
                        <Button color="success" onClick={submitFeedback}>
                          Submit
                        </Button>
                      </ModalFooter>
                    </Modal>
        <div style={{ position: 'fixed', bottom: '35px', right: '20px' }}>
      <Button onClick={toggleFeedback} color="primary" className="fab" style={{ borderRadius: '50%', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        {/* Add your FAB icon or content here */}
        <div class="font-icon-detail">
                      <i class="now-ui-icons ui-2_chat-round" style={{ fontSize: '25px !important' }}></i>
                      {/* <p>ui-2_chat-round</p> */}
                    </div>
      </Button>
    </div>
        {
          // we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  );
};

export default PatientPage;
